import Vue from 'vue'
import Vuex from 'vuex'

// General
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import profileSettings from './profile-settings/profilesettings.module';
import loader from './app/loader.module';

// Management
import userManagement from './management/user-manage/user.module';
import userPersonelManagement from './management/user-manage/userPersonel.module';

import orderManagement from './management/order-manage/order.module';
import orderPromotionManage from './management/order-promotion-manage/orderPromotionManage.module';
import sourceDataManagement from './ancestry-data/source-data-manage/sourceData.module';
import presetMapManagement from './ancestry-data/preset-map-manage/presetMap.module';
import presetFarmersHgsManagement from './ancestry-data/farmers-hgs-manage/farmersHgs.module'
import ancientProxManagement from './ancestry-data/ancient-prox-manage/ancientProx.module';
import presetRegionManagement from './ancestry-data/preset-region-manage/presetRegion.module';
import presetEthnicityManagement from './ancestry-data/preset-ethnicity-manage/presetEthnicity.module';
import presetAncientPeriodManage from './ancestry-data/preset-ancient-period-manage/presetAncientPeriodManage.module';
import productManagement from './management/product-manage/product.module';
import dataUpdateManagement from './management/data-update-manage/dataUpdate.module';
import paymentManagement from './management/payment-manage/payment.module';
import presetRegionGrupManagement from './ancestry-data/preset-region-grup-manage/presetRegionGrup.module';
import ticketManagement from './management/ticket-manage/ticketManage.module';
import presetMixedModeManage from './ancestry-data/preset-mixedmode-manage/presetMixedModeManage.module';
import presetIndividualManage from './ancestry-data/preset-individual-manage/presetIndividual.module';

// Trait
import traitCategoryManage from './trait-manage/trait-datas/traitCategory.module';
import traitFeatureManage from './trait-manage/trait-datas/traitFeature.module';
import traitGenotypeManage from './trait-manage/trait-datas/traitGenotype.module';
import traitMarkerManage from './trait-manage/trait-datas/traitMarker.module';
import traitEffectManage from './trait-manage/trait-datas/traitEffect.module';
import traitPopulationManage from './trait-manage/trait-datas/traitPopulation.module';

import traitManage from './trait-manage/traits/trait.module';
import traitFrequencyManage from './trait-manage/traitFrequency.module';

import traitLogsManage from './trait-manage/trait-logs/traitLogs.module';

import businessPartnerLink from './business-partner-manage/businessPartnerLink.module';

// Logs
import logsManagement from './management/logs-manage/logs.module';

// Reports
import homeReportManagement from './management/home-report-manage/homeReport.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // General
    app,
    loader,
    appConfig,
    verticalMenu,
    profileSettings,

    // Management
    userManagement,
    userPersonelManagement,
    
    orderManagement,
    orderPromotionManage,
    sourceDataManagement,
    presetMapManagement,
    presetFarmersHgsManagement,
    presetEthnicityManagement,
    presetAncientPeriodManage,
    ancientProxManagement,
    presetRegionManagement,
    productManagement,
    dataUpdateManagement,
    paymentManagement,
    presetRegionGrupManagement,
    ticketManagement,
    presetMixedModeManage,
    presetIndividualManage,

    // Trait
    traitCategoryManage,
    traitEffectManage,
    traitFeatureManage,
    traitGenotypeManage,
    traitMarkerManage,
    traitPopulationManage,
    traitManage,
    traitFrequencyManage,
    traitLogsManage,

    businessPartnerLink,
    
    // Logs
    logsManagement,

    // Reports
    homeReportManagement,
  },
  strict: process.env.DEV,
})
