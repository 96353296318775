import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserRole, Role } from '@/auth/utils'
import management from './routes/management'
import ancestryData from './routes/ancestry-data'
import trait from './routes/trait'
import order from './routes/order'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', 
      redirect: { name: 'manage-home' },
      meta: {
        authorize: [Role.SuperAdmin, Role.SuperOperator],
      },
    },
    ...management,
    ...order,
    ...ancestryData,
    ...trait,

    {
      path: '/profile-settings',
      name: 'profile-settings',
      component: () => import('@/views/profile-settings/ProfileSettings.vue'),
      meta: {
        authorize: [],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta

  const isLoggedIn = isUserLoggedIn()
  const currentUserRole = getUserRole()
  
  if (authorize) {
    if (!isLoggedIn) {
      // not logged in so redirect to login page with the return url
      return next({ name: 'login' });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUserRole)) {
      // role not authorised so redirect to home page
      if ((currentUserRole === Role.Admin || currentUserRole === Role.Personel) && to.name !== 'manage-home') {
        next({ name: 'manage-home' });
      } else {
        next({ path: '/' });
      }
    }
  }

  return next()
})

export default router
