import { Role } from '@/auth/utils'

export default [
  {
    path: '/management/home',
    name: 'manage-home',
    component: () => import('@/views/management/Home.vue'),
    meta: {
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/homebase',
    name: 'manage-homebase',
    component: () => import('@/views/management/HomeBasic.vue'),
    meta: {
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Kullanıcı Yönetim
  {
    path: '/management/user',
    name: 'manage-user',
    component: () => import('@/views/management/user-manage/UserList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/user/:id',
    name: 'manage-user-save',
    component: () => import('@/views/management/user-manage/user-manage-detail/UserSaveDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'User',
          to: '/management/user',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/user-personel',
    name: 'manage-user-personel',
    component: () => import('@/views/management/user-personel-manage/UserPersonelList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/user-personel/:id',
    name: 'manage-user-personel-save',
    component: () => import('@/views/management/user-personel-manage/UserPersonelSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'User Personel',
          to: '/management/user-personel',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Ticket
  {
    path: '/management/ticket-open',
    name: 'manage-ticket-open',
    component: () => import('@/views/management/others/ticket-manage/OpenTicketList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Open Tickets',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/ticket-closed',
    name: 'manage-ticket-closed',
    component: () => import('@/views/management/others/ticket-manage/ClosedTicketList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Closed Tickets',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/ticket-details/:id',
    name: 'manage-ticket-detail',
    component: () => import('@/views/management/others/ticket-manage/details/ManageTicketDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ticket detail',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Reports
  {
    path: '/management/report/orders',
    name: 'manage-report-order',
    component: () => import('@/views/management/reports/order-reports/OrderReportList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Reports',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Product 
  {
    path: '/management/product',
    name: 'manage-product',
    component: () => import('@/views/management/product-manage/ProductList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Products',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/product/:id',
    name: 'manage-product-save',
    component: () => import('@/views/management/product-manage/product-details/ProductDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Products',
          to: '/management/product',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Order Promotion
  {
    path: '/management/order-promotion',
    name: 'manage-order-promotion',
    component: () => import('@/views/management/order-promotion-manage/OrderPromotionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order-promotion-archived',
    name: 'manage-order-promotion-archived',
    component: () => import('@/views/management/order-promotion-manage/ArchivedOrderPromotionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order-promotion/:id',
    name: 'manage-order-promotion-save',
    component: () => import('@/views/management/order-promotion-manage/detail/OrderPromotionSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
          to: '/management/order-promotion',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Data Update
  {
    path: '/management/data-update',
    name: 'manage-data-update',
    component: () => import('@/views/management/others/data-update/DataUpdateList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Data Update',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/data-update/:id',
    name: 'manage-data-update-save',
    component: () => import('@/views/management/others/data-update/data-update-detail/DataUpdateSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Data Update',
          to: '/management/manage-data-update',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // version-control
  {
    path: '/management/version-control',
    name: 'manage-version-control',
    component: () => import('@/views/management/others/version-control/VersionControlForm.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Versions',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Business Partner Link
  {
    path: '/management/business-partner-link',
    name: 'manage-business-partner-link',
    component: () => import('@/views/business-partner-manage/business-partner-link/BusinessPartnerLinkList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Business Partner Links',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/business-partner-file',
    name: 'manage-business-partner-file',
    component: () => import('@/views/business-partner-manage/business-partner-link/BusinessPartnerLinkUploadFile.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Business Partner Links',
        },
      ],
      authorize: [Role.Admin],
    },
  },
]
