import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
const getDefaultState = () => {
  return {
    order: {
      id: 0,
      isActive: true,
      orderNo: null,
      subtotal: null,
      currencyUnit: null,
      orderTitle: null,
      user: null,
      userId: null,
      payment: null,
      paymentId: null,
      presetRegion: null,
      presetRegionId: null,
      paymentType: null,
      paymentTypeLabel: null,
      orderStatus: null,
      orderStatusLabel: null,
      orderDate: null,
    },
    orderItems: [],
    orderResults: [],
    orderResultsTraitAndHealths: [],
    orderUploadedFiles: [],
  }
}

const state = getDefaultState()

const getters = {
  getOrderField(state) {
    return getField(state.order);
  },

  getOrderUploadedFiles(state) {
    return state.orderUploadedFiles;
  },

  getOrderResults(state) {
    return state.orderResults;
  },
  getOrderResultsTraitAndHealths(state) {
    return state.orderResultsTraitAndHealths;
  },
}

const mutations = {
  updateOrderField(state, field) {
    updateField(state.order, field);
  },

  setOrderObjects(state, data) {
    state.order = data.order;
    state.orderItems = data.orderItems;
    state.orderResults = data.orderResults;
    state.orderResultsTraitAndHealths = data.orderResultsTraitAndHealths;
    state.orderUploadedFiles = data.orderUploadedFiles;
  },

  resetOrderState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchOrders(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('order/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchOrder(ctx, queryParams) {
    return ApiService.get(`order/get/${queryParams.id}`, queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          ctx.commit('setOrderObjects', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  saveOrder({ state }, queryParams) {
    return new Promise((resolve, reject) => {

      ApiService.post('order/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeOrder(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`order/remove/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveOrderResult(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('OrderResult/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeOrderResult(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`OrderResult/remove/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  downloadUploadOrderFile(context, url) {
    return new Promise((resolve, reject) => {
      ApiService.download(`OrderFile/download-file/${url}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeOrderFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`OrderFile/remove/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
