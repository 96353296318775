import { Role } from '@/auth/utils'

export default [
  // Preset Ethnicity
  {
    path: '/management/preset-ethnicity',
    name: 'manage-preset-ethnicity',
    component: () => import('@/views/ancestry-data/definations/preset-ethnicity/PresetEthnicityList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ethnicity',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ethnicity/:id',
    name: 'manage-preset-ethnicity-save',
    component: () => import('@/views/ancestry-data/definations/preset-ethnicity/PresetEthnicitySave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ethnicity Save',
          to: '/management/preset-ethnicity',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Region Grup
  {
    path: '/management/preset-region-grup',
    name: 'manage-preset-region-grup',
    component: () => import('@/views/ancestry-data/definations/preset-region-grup/ancestry/PresetRegionGrupList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Regions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-region-grup/:id',
    name: 'manage-preset-region-grup-save',
    component: () => import('@/views/ancestry-data/definations/preset-region-grup/ancestry/PresetRegionGrupSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
          to: '/management/preset-region-grup',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/preset-region-pc-grup',
    name: 'manage-pca-region-grup',
    component: () => import('@/views/ancestry-data/definations/preset-region-grup/pca/PresetRegionPcaGrupList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Regions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-region-pca-grup/:id',
    name: 'manage-pca-region-grup-save',
    component: () => import('@/views/ancestry-data/definations/preset-region-grup/pca/PresetRegionPcaGrupSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region PCA Grup',
          to: '/management/preset-region-pca-grup',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Region
  {
    path: '/management/preset-region',
    name: 'manage-preset-region',
    component: () => import('@/views/ancestry-data/definations/preset-region/ancestry/PresetRegionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/pca-region',
    name: 'manage-pca-region',
    component: () => import('@/views/ancestry-data/definations/preset-region/pca/PcaRegionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Pca Region',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  
  {
    path: '/management/preset-region/:id',
    name: 'manage-preset-region-save',
    component: () => import('@/views/ancestry-data/definations/preset-region/ancestry/preset-region-detail/PresetRegionDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
          to: '/management/preset-region',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/pca-region/:id',
    name: 'manage-pca-region-save',
    component: () => import('@/views/ancestry-data/definations/preset-region/pca/pca-region-detail/PcaRegionDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Pca Region',
          to: '/management/pca-region',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Ancient Period
  {
    path: '/management/preset-ancient-period',
    name: 'manage-preset-ancient-period',
    component: () => import('@/views/ancestry-data/definations/preset-ancient-period/PresetAncientPeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ancient-period/:id',
    name: 'manage-preset-ancient-period-save',
    component: () => import('@/views/ancestry-data/definations/preset-ancient-period/PresetAncientPeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
          to: '/management/preset-ancient-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset MixedMode Period
  {
    path: '/management/preset-ancient-mixedmode-period',
    name: 'manage-preset-ancient-mixedmode-period',
    component: () => import('@/views/ancestry-data/definations/preset-mixedmode-period/ancient-mixedmode/PresetMixedModePeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ancient-mixedmode-period/:id',
    name: 'manage-preset-ancient-mixedmode-period-save',
    component: () => import('@/views/ancestry-data/definations/preset-mixedmode-period/ancient-mixedmode/PresetMixedModePeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Mixed Mode Period',
          to: '/management/preset-ancient-mixedmode-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/preset-modern-mixedmode-period',
    name: 'manage-preset-modern-mixedmode-period',
    component: () => import('@/views/ancestry-data/definations/preset-mixedmode-period/modern-mixedmode/PresetMixedModePeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Modern Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-modern-mixedmode-period/:id',
    name: 'manage-preset-modern-mixedmode-period-save',
    component: () => import('@/views/ancestry-data/definations/preset-mixedmode-period/modern-mixedmode/PresetMixedModePeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Mixed Mode Period',
          to: '/management/preset-modern-mixedmode-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Source Data
  {
    path: '/management/source-data',
    name: 'manage-source-data',
    component: () => import('@/views/ancestry-data/definations/source-data/SourceDataList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Source Data',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/source-data/:id',
    name: 'manage-source-data-save',
    component: () => import('@/views/ancestry-data/definations/source-data/SourceDataSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Source Data',
          to: '/management/source-data',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Map Data
  {
    path: '/management/preset-map',
    name: 'manage-preset-map',
    component: () => import('@/views/ancestry-data/definations/preset-map/PresetMapList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Periodical Proximity',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-map/:id',
    name: 'manage-preset-map-save',
    component: () => import('@/views/ancestry-data/definations/preset-map/preset-map-detail/PresetMapDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Periodical Proximity',
          to: '/management/preset-map',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Farmers HGs Data
  {
    path: '/management/preset-farmsers-hgs',
    name: 'manage-preset-farmsers-hgs',
    component: () => import('@/views/ancestry-data/definations/farmers-hgs/PresetFarmersHgsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Farmer & HGs definition',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-farmsers-hgs/:id',
    name: 'manage-preset-farmsers-hgs-save',
    component: () => import('@/views/ancestry-data/definations/farmers-hgs/preset-farmer-hgs-detail/PresetFarmserHgsDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Farmer & HGs definition',
          to: '/management/preset-farmsers-hgs',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Ancient Prox Data
  {
    path: '/management/ancient-prox',
    name: 'manage-ancient-prox',
    component: () => import('@/views/ancestry-data/definations/ancient-proximity/AncientProxList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient Proximity',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/ancient-prox/:id',
    name: 'manage-ancient-prox-save',
    component: () => import('@/views/ancestry-data/definations/ancient-proximity/ancient-prox-detail/AncientProxDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient Proximity',
          to: '/management/ancient-prox',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Individual Ancient
  {
    path: '/management/ancient-individual',
    name: 'manage-ancient-individual',
    component: () => import('@/views/management/definations/preset-individual/PresetIndividualList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient (individual)',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/ancient-individual/:id',
    name: 'manage-ancient-individual-save',
    component: () => import('@/views/management/definations/preset-individual/preset-individual-detail/PresetIndividualSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient (individual)',
          to: '/management/ancient-individual',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },
]
