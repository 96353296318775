import ApiService from '@/common/api.service'

import { getField, updateField } from 'vuex-map-fields';
import { getDataVersion } from '../utils'

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetMapForm: {
      id: 0,
      isActive: true,
      code: null,
      title: null,
      geoData: null,
      description: null,
      supplementaryInfo: null,
      referenceSamples: null,
      researchLinks: null,
      sourceCodes: null,
      pictureUrl: null,
      logoUrl: null,
      color: null,
      dataVersion: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getMapField(state) {
    return getField(state.presetMapForm);
  },
  getMapForm(state) {
    return state.presetMapForm;
  },
}

const mutations = { 
  updateMapField(state, field) {
    updateField(state.presetMapForm, field);
  },
  setMapField(state, data) {
    state.presetMapForm = data;
  },
  resetMapField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchPresetMaps({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.get('PresetMapData/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetMap({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetMapData/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetMap({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.post('PresetMapData', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetMap({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetMapData/delete/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
