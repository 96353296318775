import ApiService from '@/common/api.service'
import { getDataVersion } from '../utils'

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetRegionList: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
  setPresetRegionList(state, list) {
    state.presetRegionList = list;
  },
}

const actions = {
  fetchPresetRegionList(ctx, queryParams) {
    queryParams.dataVersion = getDataVersion(ctx.rootState)
    return ApiService.get('PresetRegion', queryParams, this)
    .then(response => {
      if (response) {
        ctx.commit('setPresetRegionList', response.result);
      }
      return response;
    });
  },

  fetchPresetRegions(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(ctx.rootState)
      ApiService.get('PresetRegion/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetRegion(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetRegion/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetRegion(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(ctx.rootState)
      ApiService.post('PresetRegion', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetRegion(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetRegion/delete/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
